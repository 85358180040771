import React from 'react'
import SEO from '~/components/seo'
import ProductGrid from '../components/ProductGrid'
import Image from '../components/image'
import CategoryTitle from '../components/Title/CategoryTitle'
import { Head, Text, TextContainer } from '../styles/productListPage'
import Scale from '../components/Scale'
import { graphql, useStaticQuery } from 'gatsby'
import PageTransitionWhite from '../components/Animation/PageTransitionWhite'

const NecklaceListPage = () => {
  const { allShopifyProduct } = useStaticQuery(
    graphql`
      query {
        allShopifyProduct(
          sort: { fields: [createdAt], order: DESC }
          filter: { productType: { eq: "ネックレス" } }
        ) {
          edges {
            node {
              tags
              id
              title
              handle
              shopifyId
              createdAt
              images {
                id
                originalSrc
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 910) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
              variants {
                price
              }
            }
          }
        }
      }
    `
  )
  return (
    <>
      <SEO
        title="ネックレス一覧"
        keywords={[`高天原`, `天然石`, `パワーストーン`, `アクセサリー`, `ブレスレット`, `ネックレス`]}
      />
      <Head>
        <Image filename="photo/necklace.jpg" alt="天然石・パワーストーンのネックレス" />
        <Scale />
        <TextContainer>
          <CategoryTitle en="Necklace" label="ネックレス" color="white" />
          <Text>天然石の魅力を引き立てるシンプルなデザイン。</Text>
        </TextContainer>
      </Head>
      <ProductGrid data={allShopifyProduct.edges} />
      <PageTransitionWhite />
    </>
  )
}

export default NecklaceListPage
